import { ChargeIntent } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { Order, OrderStatus, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  checkoutStage,
  uouActionAtCheckout,
  couponErrorAtCheckout,
  planPurchased,
  pricingPlansUouCheckoutError,
} from '@wix/bi-logger-membership/v2';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import type { HttpError } from '@wix/fe-essentials/http-client';
import validators from '@wix/fed-sec-utils/validators/isEmail';
import type { FormValues } from '@wix/form-viewer';
import { initFormController } from '@wix/form-viewer/controller';
import { isFreePlan, isRecurringPlan } from '@wix/pricing-plans-utils';
import { ControllerFlowAPI, ControllerParams, HttpClient, IUser, IWixAPI } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID, BOOKINGS_SECTION_ID, EXPERIMENTS } from '../../../constants';
import { BenefitsApi, OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { PaymentMethodsApi } from '../../../services/payment-methods';
import { SettingsApi } from '../../../services/settings';
import { WarmupData } from '../../../services/WarmupData';
import {
  OnGetFullyDiscountedPlanFn,
  CheckoutData,
  CheckoutProps,
  CommonProps,
  IntegrationData,
  MessageCode,
  OnBeforeStartPaymentFn,
  PromisifyFn,
  ModalType,
  noModal,
  OnGetFreePlanFn,
  OnContinueAsGuestFn,
  CheckoutStage,
} from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { CheckoutAction, CheckoutError, toBIPaymentType } from '../../../utils/bi';
import { ymdToDate } from '../../../utils/date';
import { getOrderCoupon, getPricesCoupon } from '../../../utils/domainUtils';
import { errorToMessage, getApplicationErrorCode, PurchaseLimitExceededError, toError } from '../../../utils/errors';
import { isDayful } from '../../../utils/is-dayful';
import { PaymentResultReader } from '../../../utils/PaymentResultReader';
import { getUserData } from '../../../utils/user';
import { Router } from './Router';

export class CheckoutController {
  protected couponCode?: string;
  public initializeUser: (user: IUser) => Promise<void> = () => Promise.resolve(undefined);

  constructor(
    protected setProps: (props: Partial<CommonProps & CheckoutProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected benefitsApi: BenefitsApi,
    protected premiumApi: PremiumApi,
    protected paymentMethodsApi: PaymentMethodsApi,
    protected analytics: Analytics,
    protected settingsApi: SettingsApi,
    protected warmupData: WarmupData,
  ) {}

  async initialize(checkoutData: CheckoutData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.CheckoutPageInitialized);
    const {
      currentUser: { loggedIn, id },
    } = this.wixCodeApi.user;
    const { planId, memberId, orderId, integrationData } = checkoutData;
    let plans: PublicPlan[] = [];
    try {
      plans = await this.warmupData.cache('checkout.plan', () => this.plansApi.loadPaidPlans({ planIds: [planId] }));
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.CheckoutPageInitialized);

    if (plans.length < 1) {
      this.router.gotoList(integrationData, MessageCode.PLAN_NOT_FOUND);
    } else {
      const [selectedPlan] = plans;
      const isSameMember = loggedIn && memberId === id;
      try {
        await this.update(
          selectedPlan,
          integrationData,
          isSameMember && orderId
            ? await this.warmupData.cache('checkout.order', () => this.ordersApi.getOrder(orderId))
            : undefined,
        );
      } catch (e) {
        this.setProps({ message: errorToMessage(toError(e)), isCheckoutDataInitialized: true });
      }
    }
  }

  async update(selectedPlan: PublicPlan, integrationData: IntegrationData, order?: Order): Promise<void> {
    this.registerInitializeUser(selectedPlan, order, integrationData);
    this.setProps({ isCheckoutDataInitialized: false });
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.CheckoutPageLoaded);
    this.analytics.viewContent(selectedPlan);
    const [hasCoupons, prices, benefits, guestCheckoutEnabled] = await this.warmupData.cache('checkout.data', () =>
      Promise.all([
        this.ordersApi.hasCoupons(),
        this.getPricePreview(selectedPlan, order),
        this.getBookingBenefits(selectedPlan),
        this.isGuestCheckoutEnabled(integrationData),
      ]),
    );
    await this.setupForm(selectedPlan);
    this.setProps({
      order,
      prices,
      selectedPlan,
      logout: this.logout,
      loginOnCheckout: () => this.wixCodeApi.user.promptLogin({ mode: 'login', modal: true }),
      signupOnCheckout: () => this.wixCodeApi.user.promptLogin({ mode: 'signup', modal: true }),
      navigateToStatus: this.navigateToStatus,
      benefits,
      trackInitiateCheckout: () => this.analytics.initiateCheckout(selectedPlan),
      trackSelectPayment: (id: string) => this.analytics.selectPayment(id),
      demoBuyNowClicked: () => this.navigateToDemoStatus({ plan: selectedPlan, integrationData, guestCheckoutEnabled }),
      biCheckoutStage: (stage) =>
        this.flowAPI.bi?.report(
          checkoutStage({ stage, planGuid: selectedPlan.id, guestCheckout: guestCheckoutEnabled }),
        ),
      biPlanPurchased: (result: PaymentCompleteResultPublic, purchacedOrder: Order) =>
        this.flowAPI.bi?.report(
          planPurchased({
            paymentStatus: result.clientStatus,
            paymentMethodType: result.paymentMethod,
            duration:
              (selectedPlan.pricing?.subscription
                ? selectedPlan.pricing.subscription.cycleCount
                : selectedPlan.pricing?.singlePaymentForDuration?.count) ?? undefined,
            paymentType: toBIPaymentType(!!selectedPlan.pricing?.subscription),
            planGuid: selectedPlan.id,
            currency: selectedPlan.pricing?.price?.currency,
            price: Math.round(parseFloat(selectedPlan.pricing?.price?.value ?? '0') * 100),
            membershipId: purchacedOrder.id,
            couponId: getOrderCoupon(purchacedOrder)?.id,
            transactionId: result.chargeId,
          }),
        ),
      updatePriceDetails: this.updatePriceDetails,
      updatePriceDetailsError: undefined,
      couponInputMode: 'trigger',
      couponCode: (this.couponCode = undefined),
      removeCoupon: this.removeCoupon,
      onBeforeStartPayment: this.onBeforeStartPayment,
      onBeforeStartPaymentStatus: undefined,
      updateStartDateError: undefined,
      applyCouponError: undefined,
      hasCoupons,
      onGetFreePlan: this.onGetFreePlan,
      onGetFullyDiscountedPlan: this.onGetFullyDiscountedPlan,
      zeroPricePlanCheckoutStatus: undefined,
      guestCheckoutEnabled,
      guestEmail: undefined,
      guestCheckoutLoading: false,
      guestEmailConfirmed: false,
      guestEmailError: undefined,
      onContinueAsGuest: this.onContinueAsGuest,
      onContinueAsGuestV2: this.onContinueAsGuestV2,
      onEditGuestEmail: () => {
        this.setProps({ guestEmailConfirmed: false });
      },
    });

    this.setProps({ isCheckoutDataInitialized: true });

    try {
      const { currentUser } = this.wixCodeApi.user;
      if (currentUser.loggedIn) {
        // @todo: s.dubinskas: maybe needs better refactoring, but for now its hotfix
        await this.updateUserInfo(currentUser);
      }

      if (
        !isFreePlan(selectedPlan) &&
        (await this.warmupData.cache('checkout.upgrade', () => this.premiumApi.shouldUpgrade()))
      ) {
        this.setProps({ shouldUpgrade: true });
        return this.showNonPremiumSiteModal(integrationData);
      }

      if (this.isAlertModalsEnabled() && !isFreePlan(selectedPlan)) {
        const supportedChargeIntents = await this.warmupData.cache('checkout.chargeIntents', () =>
          this.paymentMethodsApi.getSupportedChargeIntents(),
        );
        if (supportedChargeIntents.length === 0) {
          return this.openCannotAcceptPaymentsModal(integrationData, CheckoutError.NO_PAYMENT_METHOD);
        }

        if (isRecurringPlan(selectedPlan) && !supportedChargeIntents.includes(ChargeIntent.RECURRING)) {
          return this.openCannotAcceptPaymentsModal(integrationData, CheckoutError.NO_RECURRING_PAYMENT_METHOD);
        }
      }

      await this.initializeUser?.(currentUser);
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
  }

  private getPricePreview(plan: PublicPlan, order?: Order) {
    return order?.pricing?.prices ?? this.ordersApi.getPricePreview(plan.id!);
  }

  private async setupForm(plan: PublicPlan) {
    const formId = plan.formId;
    if (this.flowAPI.experiments.enabled(EXPERIMENTS.ADDITIONAL_INFO_IN_CHECKOUT) && formId) {
      this.flowAPI.fedops.interactionStarted('init_form_controller');
      try {
        this.setProps({ skipAdditionalInfoStep: true });
        await initFormController(this.flowAPI, { formId });
        this.setProps({ skipAdditionalInfoStep: false });
        this.flowAPI.fedops.interactionEnded('init_form_controller');
      } catch (e) {
        this.flowAPI.errorMonitor.captureException(toError(e));
      }
    }
  }

  private async getBookingBenefits(plan: PublicPlan) {
    try {
      const isBookingsInstalled = await this.wixCodeApi.site.isAppSectionInstalled({
        sectionId: BOOKINGS_SECTION_ID,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      });
      return isBookingsInstalled ? this.benefitsApi.listPlanBookingsBenefits(plan.id ?? '') : [];
    } catch (err) {
      // TODO: What to do when benefit request fails?
      return [];
    }
  }

  private async isGuestCheckoutEnabled(integrationData: IntegrationData): Promise<boolean> {
    try {
      const settings = await this.settingsApi.getSettings();
      const isCustomCheckout = await this.isCustomCheckoutFlow(integrationData);
      return !isCustomCheckout && !!settings?.guestCheckout?.enableGuestCheckout;
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
      return false;
    }
  }

  private registerInitializeUser(selectedPlan: PublicPlan, order: Order | undefined, integrationData: IntegrationData) {
    this.initializeUser = async (user) => {
      if (user.loggedIn) {
        this.setProps({ isCheckoutDataInitialized: false });
        this.updateUserInfo(user);
        if (!order) {
          if (isFreePlan(selectedPlan)) {
            await this.createOrderForFreePlan({ plan: selectedPlan, integrationData });
          } else {
            await this.createOrder(selectedPlan, integrationData);
          }
        }
        this.setProps({ isCheckoutDataInitialized: true });
      }
    };
  }

  private showNonPremiumSiteModal = async (integrationData: IntegrationData) => {
    const { currentUser } = this.wixCodeApi.user;
    const isAdmin = currentUser.role === 'Admin';
    if (this.isAlertModalsEnabled()) {
      if (isAdmin) {
        this.setProps({ modal: { type: ModalType.Upgrade, onClose: this.closeUpgradeModal } });
      } else {
        this.openCannotAcceptPaymentsModal(integrationData, CheckoutError.NO_PREMIUM);
      }
    } else if (isAdmin) {
      this.setProps({
        showUpgradeModal: true,
        closeUpgradeModal: this.closeUpgradeModal,
      });
    } else if (this.isAcceptPaymentsErrorEnabled()) {
      this.setProps({ message: MessageCode.ACCEPT_PAYMENTS_FEATURE_MISSING });
    }
  };

  private closeUpgradeModal = () => {
    if (this.isAlertModalsEnabled()) {
      this.setProps({ modal: noModal, message: MessageCode.CHECKOUT_DEMO });
    } else {
      this.setProps({ showUpgradeModal: false, message: MessageCode.CHECKOUT_DEMO });
    }
  };

  private createOrderForFreePlan = async (params: { plan: PublicPlan; integrationData: IntegrationData }) => {
    const { plan, integrationData } = params;
    try {
      const orderPreview = await this.ordersApi.createOrderPreview(plan.id!);
      this.setProps({ order: orderPreview });
    } catch (e) {
      if (e instanceof PurchaseLimitExceededError && this.isAlertModalsEnabled()) {
        this.openPlanAlreadyPurchasedModal(plan, integrationData);
      } else {
        this.setProps({ message: errorToMessage(toError(e)) });
      }
    }
  };

  private createOrder = async (plan: PublicPlan, integrationData: IntegrationData) => {
    try {
      const order = await this.ordersApi.createOrderIfNotOverLimit(plan.id!, {
        isAcceptPaymentsErrorEnabled: this.isAcceptPaymentsErrorEnabled(),
      });
      if (order) {
        const prices = this.couponCode ? await this.getPricesWithCoupon(plan) : order?.pricing?.prices;
        this.setProps({ order, prices });
      }
    } catch (e) {
      if (e instanceof PurchaseLimitExceededError && this.isAlertModalsEnabled()) {
        this.openPlanAlreadyPurchasedModal(plan, integrationData);
      } else {
        this.setProps({ message: errorToMessage(toError(e)) });
      }
    }
  };

  private getPricesWithCoupon = async (selectedPlan: PublicPlan): Promise<SpannedPrice[] | undefined> => {
    try {
      return this.ordersApi.getPricePreview(selectedPlan.id!, this.couponCode);
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
      this.setProps({ couponCode: (this.couponCode = undefined) });
    }
  };

  updateUserInfo = async (user: IUser) => {
    const userData = getUserData(user);
    try {
      const userEmail = await user.getEmail();
      this.setProps({ user: userData, userEmail });
    } catch (e) {
      this.flowAPI.reportError(new Error('Failed to get user email: ' + e));
      this.setProps({ user: userData });
    }
  };

  navigateToStatus: CheckoutProps['navigateToStatus'] = ({
    result,
    plan,
    order,
    integrationData,
    guestCheckoutEnabled,
  }) => {
    const resultReader = new PaymentResultReader(result);
    this.router.gotoStatus({
      plan,
      order,
      integrationData,
      config: {
        ok: resultReader.isOk(),
        error: resultReader.getTranslatedError(),
      },
      guestCheckoutEnabled,
    });
  };

  navigateToDemoStatus = (params: {
    plan: PublicPlan;
    integrationData: IntegrationData;
    guestCheckoutEnabled: boolean;
  }) =>
    this.router.gotoStatus({
      plan: params.plan,
      order: {},
      integrationData: params.integrationData,
      config: { ownerDemo: true },
      guestCheckoutEnabled: params.guestCheckoutEnabled,
    });

  logout = () => {
    this.wixCodeApi.user.logout();
  };

  updateStartDate = async (orderId: string, dateYmd: string) => {
    this.setProps({
      updateStartDateError: undefined,
    });
    const date = ymdToDate(dateYmd);
    // @sarunas: this should be improved to allow reset start date to today
    if (date.getTime() >= Date.now()) {
      try {
        await this.ordersApi.updateOrderValidFrom(orderId, date);
      } catch (e) {
        this.flowAPI.reportError(toError(e));
        this.setProps({
          updateStartDateError: {
            message: toError(e).message, // @todo: map it to actual error message
          },
        });
        throw e;
      }
    }
  };

  updatePriceDetails = async (planId: string, couponCode: string) => {
    try {
      this.setProps({
        couponLoading: true,
        updatePriceDetailsError: undefined,
      });
      const prices = await this.ordersApi.getPricePreview(planId, couponCode);
      this.setProps({
        couponCode: (this.couponCode = couponCode),
        couponInputMode: 'coupon',
        couponLoading: false,
        prices,
      });
      this.flowAPI.bi?.report(
        uouActionAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          couponId: getPricesCoupon(prices)?.id,
          planGuid: planId,
        }),
      );
    } catch (e) {
      this.flowAPI.reportError(toError(e));
      const message = HttpClient.isHttpError(e)
        ? this.couponErrorToMessage(e)
        : this.flowAPI.translations.t('payment.coupon-error.unknown');
      this.setProps({ updatePriceDetailsError: { message }, couponLoading: false });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          planGuid: planId,
          errorField: getApplicationErrorCode(e) ?? 'Unknown Error',
        }),
      );
    }
  };

  removeCoupon = async (planId: string, couponId?: string) => {
    this.setProps({
      couponCode: (this.couponCode = ''),
      couponInputMode: 'input',
      updatePriceDetailsError: undefined,
    });
    this.flowAPI.bi?.report(
      uouActionAtCheckout({
        action: CheckoutAction.RemoveCoupon,
        couponId,
        planGuid: planId,
      }),
    );
    try {
      this.setProps({
        prices: await this.ordersApi.getPricePreview(planId),
      });
    } catch (e) {
      this.flowAPI.reportError(toError(e));
    }
  };

  couponErrorToMessage = (e: HttpError) => {
    const errorCodeTranslationMap = {
      ERROR_COUPON_IS_DISABLED: 'payment.coupon-error.coupon-is-disabled',
      ERROR_COUPON_USAGE_EXCEEDED: 'payment.coupon-error.coupon-usage-exceeded',
      ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED: 'payment.coupon-error.coupon-limit-per-customer-exceeded',
      ERROR_COUPON_IS_NOT_ACTIVE_YET: 'payment.coupon-error.coupon-is-not-active-yet',
      ERROR_COUPON_HAS_EXPIRED: 'payment.coupon-error.coupon-has-expired',
      ERROR_COUPON_DOES_NOT_EXIST: 'payment.coupon-error.coupon-does-not-exists',
      ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN: 'payment.coupon-error.coupon-not-applicable-for-plan',
      ERROR_COUPON_ALREADY_APPLIED: 'payment.coupon-error.coupon-already-applied',
      COUPON_ALREADY_APPLIED: 'payment.coupon-error.coupon-already-applied',
      ERROR_INVALID_SUBTOTAL: 'payment.coupon-error.invalid-subtotal',
    };
    const errorCode: keyof typeof errorCodeTranslationMap = getApplicationErrorCode(e);
    return this.flowAPI.translations.t(errorCodeTranslationMap[errorCode] ?? 'payment.coupon-error.unknown');
  };

  applyCoupon = async (orderId: string, planId: string, couponCode: string) => {
    this.setProps({
      applyCouponError: undefined,
    });
    try {
      const order = await this.ordersApi.applyCoupon(orderId, couponCode);
      this.setProps({
        order,
        couponCode: (this.couponCode = couponCode),
        prices: order?.pricing?.prices,
      });
      return order;
    } catch (e) {
      let message;
      let code;
      if (HttpClient.isHttpError(e)) {
        code = getApplicationErrorCode(e);
        if (code === 'COUPON_ALREADY_APPLIED') {
          // @todo handle how to remove coupon for real
          return;
        } else {
          message = this.couponErrorToMessage(e);
        }
      } else {
        message = this.flowAPI.translations.t('payment.coupon-error.unknown');
      }

      this.flowAPI.reportError(toError(e));
      this.setProps({
        couponInputMode: 'input',
        applyCouponError: {
          message,
        },
      });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          planGuid: planId,
          errorField: code ?? 'Unknown Error',
        }),
      );
      throw e;
    }
  };

  createSubmission = async (formId: string, formValues: FormValues) => {
    this.flowAPI.fedops.interactionStarted('create_submission');
    try {
      const response = await this.flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: formValues,
          },
        }),
      );
      const { submission } = response.data;
      this.flowAPI.fedops.interactionEnded('create_submission');
      return submission;
    } catch (e) {
      this.flowAPI.reportError(toError(e));
    }
  };

  createAndAssignSubmission = async (orderId: string, formId: string, formValues: FormValues) => {
    this.flowAPI.fedops.interactionStarted('create_submission');
    try {
      const response = await this.flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: formValues,
          },
        }),
      );
      const { submission } = response.data;
      const order = await this.ordersApi.setSubmission(orderId, submission?.id!);
      this.setProps({
        order,
      });
      this.flowAPI.fedops.interactionEnded('create_submission');
      return order;
    } catch (e) {
      this.flowAPI.reportError(toError(e));
    }
  };

  onBeforeStartPayment: PromisifyFn<OnBeforeStartPaymentFn> = async (
    orderId,
    planId,
    { startDate, couponCode, formId, formValues },
  ) => {
    this.setProps({
      onBeforeStartPaymentStatus: undefined,
    });
    let success = true;
    try {
      if (startDate) {
        await this.updateStartDate(orderId, startDate);
      }
      if (formId && formValues) {
        await this.createAndAssignSubmission(orderId, formId, formValues);
      }
      if (couponCode) {
        await this.applyCoupon(orderId, planId, couponCode);
      }
    } catch (e) {
      success = false;
    }
    this.setProps({
      onBeforeStartPaymentStatus: { success },
    });
  };

  onGetFreePlan: OnGetFreePlanFn = async ({
    plan,
    integrationData,
    isGuest,
    guestEmail,
    guestCheckoutEnabled,
    formId,
    formValues,
  }) => {
    this.setProps({ zeroPricePlanCheckoutStatus: undefined });
    try {
      let order;
      if (isGuest) {
        order = await this.createGuestOrder(plan, guestEmail, formId, formValues);
      } else {
        let submissionId;
        if (formId && formValues) {
          const submission = await this.createSubmission(formId, formValues);
          submissionId = submission?.id ?? undefined;
        }
        order = await this.ordersApi.createOrder(
          plan.id!,
          { isAcceptPaymentsErrorEnabled: this.isAcceptPaymentsErrorEnabled() },
          submissionId,
        );
      }
      if (order) {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: true } });
        this.reportZeroPricePlanPurchse('free_plan', plan, order);
        this.router.gotoStatus({ plan, order, integrationData, guestCheckoutEnabled });
      } else {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: false } });
      }
    } catch (e) {
      this.flowAPI.reportError(toError(e));
      if (isGuest && e instanceof PurchaseLimitExceededError) {
        this.setProps({
          zeroPricePlanCheckoutStatus: { success: false },
          guestEmailConfirmed: false,
          guestEmailError: 'already-purchased',
        });
      } else {
        this.setProps({ message: errorToMessage(toError(e)), zeroPricePlanCheckoutStatus: { success: false } });
      }
    }
  };

  onGetFullyDiscountedPlan: PromisifyFn<OnGetFullyDiscountedPlanFn> = async ({
    orderId,
    plan,
    couponCode,
    integrationData,
    startDate,
    guestCheckoutEnabled,
    formId,
    formValues,
  }) => {
    this.setProps({ zeroPricePlanCheckoutStatus: undefined });
    try {
      if (startDate) {
        await this.updateStartDate(orderId, startDate);
      }
      if (orderId && formId && formValues) {
        await this.createAndAssignSubmission(orderId, formId, formValues);
      }
      const order = await this.applyCoupon(orderId, plan.id!, couponCode);
      if (order?.status === OrderStatus.ACTIVE) {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: true } });
        this.reportZeroPricePlanPurchse('full_discount', plan, order);
        this.router.gotoStatus({
          plan,
          order,
          integrationData,
          config: { ok: true },
          guestCheckoutEnabled,
        });
      } else {
        throw new Error('Invalid order status for discounted plan checkout: ' + order?.status);
      }
    } catch (e) {
      this.flowAPI.reportError(toError(e));
      this.setProps({ message: errorToMessage(toError(e)), zeroPricePlanCheckoutStatus: { success: false } });
    }
  };

  private reportZeroPricePlanPurchse = (
    zeroPricePlanType: 'free_plan' | 'full_discount',
    selectedPlan: PublicPlan,
    order: Order,
  ) =>
    this.flowAPI.bi?.report(
      planPurchased({
        paymentStatus: zeroPricePlanType,
        paymentMethodType: zeroPricePlanType,
        duration:
          (selectedPlan.pricing?.subscription
            ? selectedPlan.pricing.subscription.cycleCount
            : selectedPlan.pricing?.singlePaymentForDuration?.count) ?? undefined,
        paymentType: toBIPaymentType(!!selectedPlan.pricing?.subscription),
        planGuid: selectedPlan.id,
        currency: selectedPlan.pricing?.price?.currency,
        price: 0,
        membershipId: order.id,
        couponId: getOrderCoupon(order)?.id,
      }),
    );

  private isAlertModalsEnabled() {
    return this.flowAPI.experiments.enabled(EXPERIMENTS.ALERT_MODALS);
  }

  private isAcceptPaymentsErrorEnabled() {
    return this.flowAPI.experiments.enabled(EXPERIMENTS.ACCEPT_PAYMENTS_ERROR);
  }

  private closeModal = (integrationData: IntegrationData) => {
    this.setProps({ modal: noModal });
    this.router.gotoList(integrationData);
  };

  private openCannotAcceptPaymentsModal(integrationData: IntegrationData, errorName: CheckoutError) {
    this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName }));
    this.setProps({
      modal: {
        type: ModalType.CannotAcceptPayment,
        onClose: () => this.closeModal(integrationData),
      },
    });
  }

  private openPlanAlreadyPurchasedModal(plan: PublicPlan, integrationData: IntegrationData) {
    this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName: CheckoutError.PLAN_ALREADY_PURCHASED }));
    this.setProps({
      modal: {
        type: ModalType.PlanAlreadyPurchased,
        planName: plan.name!,
        onClose: () => this.closeModal(integrationData),
      },
    });
  }

  private onContinueAsGuest: PromisifyFn<OnContinueAsGuestFn> = async ({
    plan,
    email,
    skipOrderCreation,
    integrationData,
  }) => {
    this.setProps({ guestEmailError: undefined });

    if (!email) {
      this.setProps({ guestEmailError: 'required' });
      return;
    }

    if (!validators.isEmail(email)) {
      this.setProps({ guestEmailError: 'invalid' });
      return;
    }

    this.flowAPI.bi?.report(
      checkoutStage({
        planGuid: plan.id,
        guestCheckout: true,
        stage: isFreePlan(plan) ? CheckoutStage.GUEST_CHECKOUT_GET_FREE_PLAN : CheckoutStage.GUEST_CHECKOUT_CONTINUE,
      }),
    );

    if (skipOrderCreation) {
      this.setProps({
        guestCheckoutLoading: false,
        guestEmail: email,
        guestEmailConfirmed: true,
      });
      return;
    }

    if (isFreePlan(plan)) {
      this.setProps({ guestCheckoutLoading: true });
      await this.onGetFreePlan({ plan, integrationData, isGuest: true, guestEmail: email, guestCheckoutEnabled: true });
      this.setProps({ guestCheckoutLoading: false });
      return;
    }

    try {
      this.setProps({ guestCheckoutLoading: true });
      const order = await this.createGuestOrder(plan, email);
      if (order) {
        this.setProps({
          order,
          guestCheckoutLoading: false,
          guestEmail: email,
          guestEmailConfirmed: true,
          prices: await this.getPricesWithCoupon(plan),
        });
      } else {
        this.setProps({ guestCheckoutLoading: false, guestEmailConfirmed: false });
      }
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
      if (e instanceof PurchaseLimitExceededError) {
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          guestEmailError: 'already-purchased',
        });
      } else {
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          guestEmailError: undefined,
          message: errorToMessage(toError(e)),
        });
      }
    }
  };

  private onContinueAsGuestV2: PromisifyFn<OnContinueAsGuestFn> = async ({
    plan,
    email,
    skipOrderCreation,
    integrationData,
  }) => {
    this.setProps({ guestEmailError: undefined });

    if (!email) {
      this.setProps({ guestEmailError: 'required' });
      return;
    }

    if (!validators.isEmail(email)) {
      this.setProps({ guestEmailError: 'invalid' });
      return;
    }

    this.flowAPI.bi?.report(
      checkoutStage({
        planGuid: plan.id,
        guestCheckout: true,
        stage: isFreePlan(plan) ? CheckoutStage.GUEST_CHECKOUT_GET_FREE_PLAN : CheckoutStage.GUEST_CHECKOUT_CONTINUE,
      }),
    );

    if (skipOrderCreation) {
      this.setProps({
        guestCheckoutLoading: false,
        guestEmail: email,
        guestEmailConfirmed: true,
      });
      return;
    }

    try {
      this.setProps({ guestCheckoutLoading: true });
      if (isFreePlan(plan)) {
        this.setProps({
          guestCheckoutLoading: false,
          guestEmail: email,
          guestEmailConfirmed: true,
          prices: await this.getPricesWithCoupon(plan),
        });
      } else {
        const order = await this.createGuestOrder(plan, email);
        if (order) {
          this.setProps({
            order,
            guestCheckoutLoading: false,
            guestEmail: email,
            guestEmailConfirmed: true,
            prices: await this.getPricesWithCoupon(plan),
          });
        } else {
          this.setProps({ guestCheckoutLoading: false, guestEmailConfirmed: false });
        }
      }
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
      if (e instanceof PurchaseLimitExceededError) {
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          guestEmailError: 'already-purchased',
        });
      } else {
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          guestEmailError: undefined,
          message: errorToMessage(toError(e)),
        });
      }
    }
  };

  private createGuestOrder = async (plan: PublicPlan, email?: string, formId?: string, formValues?: FormValues) => {
    if (!email) {
      return;
    }

    const isPurchaseLimitExceeded = await this.ordersApi.isLimitExceededForGuest(plan, email);
    if (isPurchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const captchaToken = await this.requestCaptchaToken();
    if (captchaToken) {
      let submissionId;
      if (formId && formValues) {
        const submission = await this.createSubmission(formId, formValues);
        submissionId = submission?.id ?? undefined;
      }

      return this.ordersApi.createGuestOrder({
        planId: plan.id!,
        email,
        captchaToken,
        submissionId,
      });
    }
  };

  private requestCaptchaToken = async () => {
    type IWixApiWithAuthentication = IWixAPI & {
      authentication: { openCaptchaChallenge: () => Promise<string | null> };
    };

    try {
      const token = await (this.wixCodeApi as IWixApiWithAuthentication).authentication.openCaptchaChallenge();
      return token;
    } catch (e) {
      // openCaptchaChallenge promise is rejected if captcha modal is closed
      return null;
    }
  };

  private async isCustomCheckoutFlow(integrationData: IntegrationData) {
    return (
      (await isDayful(this.flowAPI, this.wixCodeApi)) ||
      Boolean(integrationData.verticalStatusContent) ||
      Boolean(integrationData.navigateToSectionProps) ||
      Boolean(integrationData.navigateToPageProps)
    );
  }
}
