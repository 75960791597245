export const EXPERIMENTS = {
  FIT_TO_CONTENT_HEIGHT: 'specs.membership.FitToContentHeight',
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
  PLAN_LIMIT: 'specs.membership.PlanLimit',
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
  ALERT_MODALS: 'specs.membership.AlertModals',
  USE_WARMUP_DATA: 'specs.membership.UseWarmupData',
  FORCE_MEMBERS_AREA_INSTALL: 'specs.membership.ForceMemberAreaInstall',
  FORCE_MEMBERS_AREA_NO_INSTALL: 'specs.membership.ForceMemberAreaNoInstall',
  SCROLL_INTO_CHECKOUT: 'specs.membership.ScrollIntoCheckout',
  ADDITIONAL_INFO_IN_CHECKOUT: 'specs.membership.AdditionalInfoInCheckout',
  DO_NOT_COLLAPSE_PAYMENTS_WIDGET: 'specs.membership.DoNotCollapsePaymentsWidget',
  PRICE_FORMATTER: 'specs.membership.PriceFormatter',
  ACCEPT_PAYMENTS_ERROR: 'specs.membership.AcceptPaymentsError',
  DEMO_PLANS_TRANSLATED: 'specs.membership.demoPlansTranslated',
  FIX_MEMBERS_AREA_HANDLE_ACTION: 'specs.membership.FixMembersAreaHandleAction',
};
