import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../constants';
import { toError } from '../utils/errors';

export class WarmupData {
  constructor(
    protected keyPrefix: string,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
  ) {}

  async cache<T>(path: string, fetcher: () => Promise<T>): Promise<T> {
    if (!this.flowAPI.experiments.enabled(EXPERIMENTS.USE_WARMUP_DATA)) {
      return fetcher();
    }

    const key = this.keyPrefix + path;
    if (this.flowAPI.environment.isSSR) {
      const data = await fetcher();
      this.wixCodeApi.window.warmupData.set(key, JSON.stringify(data));
      return data;
    } else {
      try {
        const data = this.wixCodeApi.window.warmupData.get(key);
        if (data) {
          return JSON.parse(data);
        }
      } catch (e) {
        this.flowAPI.errorMonitor.captureException(toError(e));
      }
      return fetcher();
    }
  }
}
