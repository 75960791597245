import { PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { FlowAPI } from '@wix/yoshi-flow-editor';

export const HIGHGLIGHTED_MOCK_PLAN_ID = 'some-id-2';

export const plansFixture: PublicPlan[] = [
  {
    id: 'some-id-1',
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: ['6 classes', '3 individual sessions', 'Online resources'],
    },
    description: 'Explore the basics with this introductory course',
    name: 'Beginner',
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '15',
        currency: 'USD',
      },
      singlePaymentForDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
  },
  {
    id: HIGHGLIGHTED_MOCK_PLAN_ID,
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: [
        '6 classes',
        '3 individual sessions',
        'Online resources',
        '1 guest pass',
        'Free WiFi',
        'Weekly newsletter',
      ],
    },
    description: 'Perfect for creatives, freelancers, and experienced',
    name: 'Pro',
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '10',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 12,
      },
    },
  },
  {
    id: 'some-id-3',
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: [
        '6 classes',
        '3 individual sessions',
        'Online resources',
        '1 guest pass',
        'Free WiFi',
        'Weekly newsletter',
        'Priority support',
      ],
    },
    description: 'Unlimited access to professional content',
    name: 'VIP',
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '30',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 0,
      },
    },
  },
];

export const plansFixtureTranslated = (t: FlowAPI['translations']['t']): PublicPlan[] => [
  {
    id: 'some-id-1',
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: [t('demo-plans.first-perk'), t('demo-plans.second-perk'), t('demo-plans.third-perk')],
    },
    description: t('demo-plans.first-plan.description'),
    name: t('demo-plans.first-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '15',
        currency: 'USD',
      },
      singlePaymentForDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
  },
  {
    id: HIGHGLIGHTED_MOCK_PLAN_ID,
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: [
        t('demo-plans.first-perk'),
        t('demo-plans.second-perk'),
        t('demo-plans.third-perk'),
        t('demo-plans.fourth-perk'),
        t('demo-plans.fifth-perk'),
        t('demo-plans.sixth-perk'),
      ],
    },
    description: t('demo-plans.second-plan.description'),
    name: t('demo-plans.second-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '10',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 12,
      },
    },
  },
  {
    id: 'some-id-3',
    createdDate: new Date(),
    updatedDate: new Date(),
    perks: {
      values: [
        t('demo-plans.first-perk'),
        t('demo-plans.second-perk'),
        t('demo-plans.third-perk'),
        t('demo-plans.fourth-perk'),
        t('demo-plans.fifth-perk'),
        t('demo-plans.sixth-perk'),
        t('demo-plans.seventh-perk'),
      ],
    },
    description: t('demo-plans.third-plan.description'),
    name: t('demo-plans.third-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '30',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 0,
      },
    },
  },
];
