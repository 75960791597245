import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { checkoutStage, membershipStatusLoaded, thankYouPageCtaButtonClick } from '@wix/bi-logger-membership/v2';
import { getThankYouPageSettings } from '@wix/pricing-plans-utils/client-data';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { PlansApi } from '../../../services';
import { WarmupData } from '../../../services/WarmupData';
import { CheckoutData, CheckoutStage, IntegrationData, StatusData, StatusProps } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { toMembershipStatus } from '../../../utils/bi';
import { toError } from '../../../utils/errors';
import { getPageType } from '../../../utils/pageType';
import { Router } from './Router';

export class StatusController {
  constructor(
    protected setProps: (props: Partial<StatusProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected warmupData: WarmupData,
  ) {}

  public async initialize(statusData: StatusData) {
    const { planId } = statusData.purchaseData.checkoutData;
    let plans: PublicPlan[] = [];
    try {
      plans = await this.warmupData.cache('status.plan', () => this.plansApi.loadPaidPlans({ planIds: [planId] }));
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    return this.update(statusData, plans[0]);
  }

  public async update(statusData: StatusData, plan?: PublicPlan) {
    const { ownerDemo, successful, purchaseData, error, startDate } = statusData;
    const { checkoutData } = purchaseData;
    const { integrationData, planId, guestCheckoutEnabled } = checkoutData;

    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.StatusPageLoaded);
    this.flowAPI.bi?.report(
      membershipStatusLoaded({
        membershipStatus: toMembershipStatus(getPageType({ ownerDemo, successful }, integrationData)),
      }),
    );
    return this.setProps({
      settings: getThankYouPageSettings(plan?.clientData),
      successful,
      translatedError: error,
      startDate,
      navigateFromStatusPage: () => this.navigateFromStatusPage(statusData, plan),
      navigateBackToCheckout: () => this.navigateBackToCheckout(integrationData, plan),
      biThankYouPageCtaButtonClick: () => this.flowAPI.bi?.report(thankYouPageCtaButtonClick({ buttonName: 'null' })),
      biThankYouPageOnLoad: () =>
        this.flowAPI.bi?.report(
          checkoutStage({
            stage: CheckoutStage.THANK_YOU_PAGE,
            planGuid: planId,
            guestCheckout: Boolean(guestCheckoutEnabled),
          }),
        ),
    });
  }

  navigateFromStatusPage(statusData: StatusData, plan?: PublicPlan) {
    const {
      planName,
      startDate,
      purchaseData: {
        checkoutData: { integrationData },
      },
    } = statusData;
    if (integrationData.navigateTo || integrationData.navigateToSectionProps || integrationData.navigateToPageProps) {
      this.navigateTo({
        planName,
        startDate,
        integrationData,
      });
    } else {
      const settings = getThankYouPageSettings(plan?.clientData);
      if (settings?.buttonLink) {
        this.wixCodeApi.location.to!(settings?.buttonLink);
      } else {
        this.router.gotoHomePage();
      }
    }
  }

  async navigateTo({
    planName,
    startDate,
    integrationData: { navigateToPageProps, navigateToSectionProps, navigateTo },
  }: Pick<StatusData, 'planName' | 'startDate'> & Pick<CheckoutData, 'integrationData'>) {
    if (navigateToSectionProps) {
      if (startDate) {
        navigateToSectionProps.queryParams = {
          ...(navigateToSectionProps.queryParams ?? {}),
          startDate,
          planName,
        };
      }

      const { queryParams, state, ...sectionProps } = navigateToSectionProps;
      const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl(sectionProps);

      let url = relativeUrl + '';
      if (state) {
        url += '/' + state;
      }
      if (queryParams) {
        url += '?appSectionParams=' + encodeURIComponent(JSON.stringify(queryParams));
      }
      this.wixCodeApi.location.to!(url);
    } else if (navigateToPageProps) {
      this.wixCodeApi.location.navigateTo!({ pageId: navigateToPageProps });
    } else if (navigateTo) {
      const { baseUrl } = this.wixCodeApi.location;
      if (navigateTo.startsWith('/') || navigateTo.startsWith(baseUrl)) {
        const [path, params] = toPathAndSearchParams(navigateTo);
        if (planName) {
          params.append('planName', planName);
        }
        if (startDate) {
          params.append('startDate', startDate);
        }
        const search = params.toString();
        this.wixCodeApi.location.to!(path + (search ? '?' + search : ''));
      } else {
        // Navigation outside website with our open Integrations API is dangerous as it could lead to spoofing
        // attacks. This can be allowed if final URL is validated against whitelist, e.g. something entered in
        // website/component settings.
        this.flowAPI.errorMonitor.captureMessage(`Blocked navigation to ${navigateTo} from ${baseUrl}.`);
        this.router.gotoHomePage();
      }
    }
  }

  async navigateBackToCheckout(integrationData: IntegrationData, plan?: PublicPlan) {
    if (plan) {
      this.router.gotoCheckout(plan, integrationData);
    } else {
      this.router.gotoHomePage();
    }
  }
}

function toPathAndSearchParams(relativeUrl: string): [string, URLSearchParams] {
  const queryStart = relativeUrl.indexOf('?');
  const [path, query] =
    queryStart > -1 ? [relativeUrl.substring(0, queryStart), relativeUrl.substring(queryStart)] : [relativeUrl, ''];
  return [path, new URLSearchParams(query)];
}
